
import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
//import {Checkbox, CheckboxGroup, Stack} from "@chakra-ui/react";
//@ts-ignore
import { start } from "repl";
import { setDefaultResultOrder } from "dns";
import { ClassNames } from "@emotion/react";
import { time } from "console";
import { stringify } from "querystring";
import { Dialog, Table, Box, Input, Flex, Text, Fieldset, TableCell } from '@chakra-ui/react';
import { BiAddToQueue } from "react-icons/bi";
import { RiCloseCircleFill } from "react-icons/ri";
import { BsSendFill } from "react-icons/bs";
import { MdLoop } from "react-icons/md";
import "../timeprofiles/timeprofile.css"
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";




interface ITimeProfileProps {
    id: string;
    is_bank_holiday_aware: boolean;
    name: string;
    days: {
        id: string;
        day: string;
        start_time: string;
        end_time: string;
    }
    cb: Function;
}
interface IWorkflowModalProps {
    id: string;
    initializer_code_id: string;
}
export interface IWorkflowStep {
    id: string;
    created: string;
    created_by_id: string;
    created_by_name: string;
    updated: string;
    updated_by_id: string;
    updated_by_name: string;
    name: string;
    description: string;
    definition_id: string;
    index: number;
    cooldown_time: string;
    estimated_length: string | null;
    maximum_length: string | null;
    tag: string | null;
    execution_type: string;
    code_id: string;
    events: event[];
    twilio_task_type: string | null;
    twilio_task_type_key: string | null;
}

export interface event {
    event_id: string;
    step_id: string;
    code_definition: string;
}

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '45vw',
    height: '65vh',
    borderRadius: "15px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

var startTimesDict = {
    "Monday": "10:00",
    "Tuesday": "10:00",
    "Wednesday": "10:00",
    "Thursday": "10:00",
    "Friday": "10:00",
    "Saturday": "10:00",
    "Sunday": "10:00",
};
var endTimesDict = {
    "Monday": "10:00",
    "Tuesday": "10:00",
    "Wednesday": "10:00",
    "Thursday": "10:00",
    "Friday": "10:00",
    "Saturday": "10:00",
    "Sunday": "10:00",
}
interface ITimeProfileCreateProps {
    cb: Function
}
export default function CreateTimeprofile(props: ITimeProfileCreateProps) {
    const [open, setOpen] = useState(false);
    const [steps, setSteps] = useState<IWorkflowStep[]>();
    const [value, setValue] = useState(1);
    const [initialOpen, setInitialOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState<IWorkflowStep>();
    const [is_bank_holiday_aware, setBankHoliday] = useState<boolean>(false);
    const [name, setName] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const webHelpers = useWebHelper();
    const [startTimes, setStartTimes] = useState({
        Monday: "10:00",
        Tuesday: "10:00",
        Wednesday: "10:00",
        Thursday: "10:00",
        Friday: "10:00",
        Saturday: "10:00",
        Sunday: "10:00",
    });
    const [endTimes, setEndTimes] = useState({
        Monday: "10:00",
        Tuesday: "10:00",
        Wednesday: "10:00",
        Thursday: "10:00",
        Friday: "10:00",
        Saturday: "10:00",
        Sunday: "10:00",
    })
    const [week, setWeek] = useState({
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
    });
    const handleOpen = () => {
        setOpen(true);
        setInitialOpen(true);
    }
    const handleClose = () => {
        setOpen(false)
    };
    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>,) => {
    //     setWeek({ ...week, [event.target.name]: event.target.checked });
    // }
    // below is CHAKRA version
    const handleChange = (name: string, checked: boolean) => {
        setWeek({ ...week, [name]: checked });
    }
    const handleBankHolidayChange = (event: React.ChangeEvent<HTMLInputElement>,) => {
        setBankHoliday(!is_bank_holiday_aware);
    }
    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>,) => {
        setName(event.target.value);
    }
    const handleSumbit = () => {
        setLoading(true);
        let data = {
            name: name,
            is_bank_holiday_aware: is_bank_holiday_aware,
            days: [],
        }
        //create data structure
        for (let days in week) {
            //@ts-ignore
            if (week[days] == true) {
                //@ts-ignore
                data.days.push({ week_day: days, start_time: startTimesDict[days], end_time: endTimesDict[days] })
            }
        }
        data.name = name;
        data.is_bank_holiday_aware = is_bank_holiday_aware;
        webHelpers.PutAsync("/api/workflow/timeprofile", 'helios-api', data).then((data: any) => {
            if (data.response === 400 || data === undefined) {
                return (toaster.create({

                    title: `Some server error creating this event subscriber`,
                    type: "error",

                }))
            }
            else {
                setOpen(false);
                setLoading(false);
                props.cb();
                return (toaster.create({

                    title: `New Time Profile Successfully Created`,
                    type: "success",

                }))
            }
        }).catch((error) => {
            setLoading(false);
            return (
                toaster.create({

                    title: `Could not create new Timeprofile [${error.status}]`,
                    type: "error",

                })
            )

        })
    }

    const changeMonStart = (timeValue: string) => { startTimesDict["Monday"] = timeValue; console.log("dict monday :", startTimesDict["Monday"]) }
    const changeMonEnd = (timeValue: any) => { endTimesDict["Monday"] = timeValue };
    const changeTueEnd = (timeValue: any) => { endTimesDict["Tuesday"] = timeValue };
    const changeWedEnd = (timeValue: any) => { endTimesDict["Wednesday"] = timeValue };
    const changeThuEnd = (timeValue: any) => { endTimesDict["Thursday"] = timeValue };
    const changeFriEnd = (timeValue: any) => { endTimesDict["Friday"] = timeValue };
    const changeSatEnd = (timeValue: any) => { endTimesDict["Saturday"] = timeValue };
    const changeSunEnd = (timeValue: any) => { endTimesDict["Sunday"] = timeValue };
    const changeTueStart = (timeValue: any) => { startTimesDict["Tuesday"] = timeValue };
    const changeWedStart = (timeValue: any) => { startTimesDict["Wednesday"] = timeValue };
    const changeThuStart = (timeValue: any) => { startTimesDict["Thursday"] = timeValue };
    const changeFriStart = (timeValue: any) => { startTimesDict["Friday"] = timeValue };
    const changeSatStart = (timeValue: any) => { startTimesDict["Saturday"] = timeValue };
    const changeSunStart = (timeValue: any) => { startTimesDict["Sunday"] = timeValue };

    useEffect(() => {
        if (steps !== undefined) {
            setCurrentStep(steps[value - 1])
        }
    }, [value])

    return (
        <>
            <Toaster />
            <DialogRoot
                onOpenChange={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                size={"lg"}
            >
                <Dialog.Backdrop />
                <DialogTrigger marginTop={"9px"}>
                    <Button marginLeft={"30px"} onClick={handleOpen} bgColor={"AbleBlue"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"}>
                        <BiAddToQueue />
                        Create New Profile
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogTitle>
                        <Text>Create New Time Profile</Text>

                    </DialogTitle>
                    <DialogBody padding={"10px"}>
                        <Box>
                            <div className="tab-button-container">
                                <div className="button-container">
                                    {/* TIME PROFILE FORM GOES HERE*/}
                                    <Fieldset.Root className="time-profile-form">
                                        {/* ENTER NAME GOES HERE */}
                                        <Fieldset.HelperText>Please complete the form, ensuring all fields are correct, before submitting.</Fieldset.HelperText>

                                        <Flex direction={"row"} justifyContent={"space-between"}>
                                            {/* <Text id="name-input" onChange={handleChangeName} marginTop={"11px"} w={"250px"}>Enter Name of Time Profile</Text> */}
                                            <Input placeholder="Name" type="text" autoComplete="new-password" value={name} bg={"backgroundDarkGrey"} marginTop={"5px"} onChange={handleChangeName}></Input>
                                        </Flex>
                                        <Flex direction={"row"} className="input-row" marginTop={"20px"} marginBottom={"20px"}>
                                            <Text>Is Bank Holiday Aware?</Text>
                                            <Checkbox cursor={"pointer"} checked={is_bank_holiday_aware} name="bank-holiday" onChange={() => { setBankHoliday(!is_bank_holiday_aware) }} bg={"backgroundDarkGrey"} marginLeft={"10px"} />
                                        </Flex>
                                        <Table.Root variant={"line"} width={"90%"} marginTop={"10px"} alignSelf={"center"}>
                                            <Table.Header>
                                                <Table.Row bg={"backgroundDarkGrey"}>
                                                    <Table.ColumnHeader>Days of the Week</Table.ColumnHeader>
                                                    <Table.ColumnHeader>Active?</Table.ColumnHeader>
                                                    <Table.ColumnHeader>Start Time</Table.ColumnHeader>
                                                    <Table.ColumnHeader>End Time</Table.ColumnHeader>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                <Table.Row height={"50px"} bg={"backgroundGrey"}>
                                                    <Table.Cell><Text height={"10px"}>Monday</Text></Table.Cell>
                                                    <Table.Cell> <Checkbox checked={week.Monday} name="Monday" onChange={() => handleChange("Monday", !week.Monday)} /></Table.Cell>
                                                    {/* <Table.Cell><Input type={"time"}    className={week.Monday ? "time-box-style" : "time-box-style2"}  onChange={changeMonStart} value={startTimes.Monday} disabled={!week.Monday} id="Monday-Start" /></Table.Cell> */}
                                                    <Table.Cell w={"50px"}><Input type={"time"} className={week.Monday ? "time-box-style" : "time-box-style2"}  onChange={(ev) => changeMonStart(ev.target.value)} defaultValue={startTimes.Monday} disabled={!week.Monday} id="Monday-Start" /></Table.Cell>
                                                    
                                                    <Table.Cell w={"50px"}><Input type={"time"}    className={week.Monday ? "time-box-style" : "time-box-style2"}  onChange={(ev) => changeMonEnd(ev.target.value)} defaultValue={endTimes.Monday} disabled={!week.Monday} id="Monday-End" /></Table.Cell>
                                                </Table.Row>
                                                <Table.Row height={"50px"} bg={"backgroundGrey"}>
                                                    <Table.Cell><Text height={"10px"}>Tuesday</Text></Table.Cell>
                                                    <Table.Cell> <Checkbox checked={week.Tuesday} name="Tuesday" onChange={() => handleChange("Tuesday", !week.Tuesday)} /></Table.Cell>
                                                    <Table.Cell w={"50px"}><Input type={"time"}    className={week.Tuesday ? "time-box-style" : "time-box-style2"}  onChange={(ev) => changeTueStart(ev.target.value)} defaultValue={startTimes.Tuesday} disabled={!week.Tuesday} id="Tuesday-Start" /></Table.Cell>
                                                    <Table.Cell w={"50px"}><Input type={"time"}    className={week.Tuesday ? "time-box-style" : "time-box-style2"}  onChange={(ev) => changeTueEnd(ev.target.value)} defaultValue={endTimes.Tuesday} disabled={!week.Tuesday} id="Tuesday-End" /></Table.Cell>
                                                </Table.Row>
                                                <Table.Row height={"50px"} bg={"backgroundGrey"}>
                                                    <Table.Cell><Text height={"10px"}>Wednesday</Text></Table.Cell>
                                                    <Table.Cell> <Checkbox checked={week.Wednesday} name="Wednesday" onChange={() => handleChange("Wednesday", !week.Wednesday)} /></Table.Cell>
                                                    <Table.Cell w={"50px"}><Input type={"time"}    className={week.Wednesday ? "time-box-style" : "time-box-style2"}  onChange={(ev) => changeWedStart(ev.target.value)} defaultValue={startTimes.Wednesday} disabled={!week.Wednesday} id="Wednesday-Start" /></Table.Cell>
                                                    <Table.Cell w={"50px"}><Input type={"time"}    className={week.Wednesday ? "time-box-style" : "time-box-style2"}  onChange={(ev) => changeWedEnd(ev.target.value)} defaultValue={endTimes.Wednesday} disabled={!week.Wednesday} id="Wednesday-End" /></Table.Cell>
                                                </Table.Row>

                                                <Table.Row height={"50px"} bg={"backgroundGrey"}>
                                                    <Table.Cell><Text height={"10px"}>Thursday</Text></Table.Cell>
                                                    <Table.Cell> <Checkbox checked={week.Thursday} name="Thursday" onChange={() => handleChange("Thursday", !week.Thursday)} /></Table.Cell>
                                                    <Table.Cell w={"50px"}><Input type={"time"}    className={week.Thursday ? "time-box-style" : "time-box-style2"}  onChange={(ev) => changeThuStart(ev.target.value)} defaultValue={startTimes.Thursday} disabled={!week.Thursday} id="Thursday-Start" /></Table.Cell>
                                                    <Table.Cell w={"50px"}><Input type={"time"}    className={week.Thursday ? "time-box-style" : "time-box-style2"}  onChange={(ev) => changeThuEnd(ev.target.value)} defaultValue={endTimes.Thursday} disabled={!week.Thursday} id="Thursday-End" /></Table.Cell>
                                                </Table.Row>
                                                <Table.Row height={"50px"} bg={"backgroundGrey"}>
                                                    <Table.Cell><Text height={"10px"}>Friday</Text></Table.Cell>
                                                    <Table.Cell> <Checkbox checked={week.Friday} name="Friday" onChange={() => handleChange("Friday", !week.Friday)} /></Table.Cell>
                                                    <Table.Cell w={"50px"}><Input type={"time"}    className={week.Friday ? "time-box-style" : "time-box-style2"}  onChange={(ev) => changeFriStart(ev.target.value)} defaultValue={startTimes.Friday} disabled={!week.Friday} id="Friday-Start" /></Table.Cell>
                                                    <Table.Cell w={"50px"}><Input type={"time"}    className={week.Friday ? "time-box-style" : "time-box-style2"}  onChange={(ev) => changeFriEnd(ev.target.value)} defaultValue={endTimes.Friday} disabled={!week.Friday} id="Friday-End" /></Table.Cell>
                                                </Table.Row>
                                                <Table.Row height={"50px"} bg={"backgroundGrey"}>
                                                    <Table.Cell><Text height={"10px"}>Saturday</Text></Table.Cell>
                                                    <Table.Cell> <Checkbox checked={week.Saturday} name="Saturday" onChange={() => handleChange("Saturday", !week.Saturday)} /></Table.Cell>
                                                    <Table.Cell w={"50px"}><Input type={"time"}    className={week.Saturday ? "time-box-style" : "time-box-style2"}  onChange={(ev) => changeSatStart(ev.target.value)} defaultValue={startTimes.Saturday} disabled={!week.Saturday} id="Saturday-Start" /></Table.Cell>
                                                    <Table.Cell w={"50px"}><Input type={"time"}    className={week.Saturday ? "time-box-style" : "time-box-style2"}  onChange={(ev) => changeSatEnd(ev.target.value)} defaultValue={endTimes.Saturday} disabled={!week.Saturday} id="Saturday-End" /></Table.Cell>
                                                </Table.Row>
                                                <Table.Row height={"50px"} bg={"backgroundGrey"}>
                                                    <Table.Cell><Text height={"10px"}>Sunday</Text></Table.Cell>
                                                    <Table.Cell> <Checkbox checked={week.Sunday} name="Sunday" onChange={() => handleChange("Sunday", !week.Sunday)} /></Table.Cell>
                                                    <Table.Cell w={"50px"}><Input type={"time"}    className={week.Sunday ? "time-box-style" : "time-box-style2"}  onChange={(ev) => changeSunStart(ev.target.value)} defaultValue={startTimes.Sunday} disabled={!week.Sunday} id="Sunday-Start" /></Table.Cell>
                                                    <Table.Cell w={"50px"}><Input type={"time"}    className={week.Sunday ? "time-box-style" : "time-box-style2"}  onChange={(ev) => changeSunEnd(ev.target.value)} defaultValue={endTimes.Sunday} disabled={!week.Sunday} id="Sunday-End" /></Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table.Root>
                                    </Fieldset.Root>
                                </div>


                            </div>
                        </Box>
                    </DialogBody>
                    <DialogFooter>
                        <Button alignSelf={"center"}
                            bg={"AbleBlue"} color={"white"} size={"sm"} marginLeft={"30px"}
                            _hover={{ bg: "AbleYellow", color: "white" }}
                            _active={{ bg: "#ECB500" }}
                            onClick={handleSumbit}
                            loading={loading}
                        >
                            {!loading ? <BsSendFill /> : <MdLoop />}
                            Create Profile
                        </Button>

                    </DialogFooter>
                </DialogContent>
            </DialogRoot >
        </>
    );
}
