import * as React from "react";
import { useState, useEffect } from "react";
import { IWebHelperErrorResponse, useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Table, Select, Input, Flex, Text, useDisclosure, Spinner,
    createListCollection,
    Collapsible
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import StepsComponent from "./stepsComponent";
import { BsCodeSlash } from "react-icons/bs";
import { AiFillCaretLeft, AiFillCaretRight, AiFillDelete } from "react-icons/ai";
import { BiAddToQueue } from "react-icons/bi";
import { CodeEditor } from "../code/codeEditor";
import { useColorMode } from "../ui/color-mode";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";
import { SearchBox } from "../searchBox";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from "../ui/select";


interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
interface IWorkflow {
    description: string,
    event_id: string,
    id: string,
    initializer_code_id: string,
    name: string,
    cb: Function
}

export interface IWorkflowStep {
    id: string;
    created: string;
    created_by_id: string;
    created_by_name: string;
    updated: string;
    updated_by_id: string;
    updated_by_name: string;
    name: string;
    description: string;
    definition_id: string;
    index: number;
    cooldown_time: string;
    estimated_length: string | null;
    maximum_length: string | null;
    tag: string | null;
    execution_type: string;
    code_id: string;
    events: event[];
    twilio_task_type: string | null;
    twilio_task_type_key: string | null;
    time_profile_id: string;
}
export interface event {
    event_id: string;
    step_id: string;
    code_definition: string;
}
interface Timeprofiles {
    id: string,
    is_bank_holiday_aware: boolean,
    name: string,
    days: Day[]
}
interface Day {
    day: string,
    end_time: string,
    id: string,
    start_time: string,
}
interface StepsProps {
    timeprofiles: Timeprofiles[],
    step: IWorkflowStep,
    workflow_id: string,
    cb: Function,
    refreshEventsFunction: Function,
}
interface Event {
    name: string,
    id: string,
}
export default function AddEvent(props: StepsProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [events, setEvents] = useState<Event[]>([]);
    const CacheContext = useCache();
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [selectedEvent, setSelectedEvent] = useState<string>("");
    const [goToStage2, setGoToStage2] = useState<boolean>(false);
    const [code, setCode] = useState<string>("");
    const { colorMode, toggleColorMode } = useColorMode();
    const [trueMaxPage, setTrueMaxPage] = useState<number>(0);

    const webHelpers = useWebHelper();

    const handleClickOpen = () => {
        onOpen();
        searchEvents("");
        setName("");
        setDescription("");
        setGoToStage2(false);
        setSelectedEvent("");
    }
    const handleClose = () => {
        onClose();
        props.refreshEventsFunction();
    }

    const searchEvents = (searchString: string) => {
        if (searchString !== '') {
            webHelpers.GetAsync('/api/events/?filter=' + searchString, 'helios-api').then((data: any) => {
                if (data.length === 0 || data.response === 400) {
                    // toaster.create({
                    //     
                    //     title: `Unable to fetch permissions with this name, please try another`,
                    //     type: "error",
                    //     
                    // });
                    setEvents([]);
                    setMaxPages(1);
                }
                else {

                    if (data.length / rowsPerPage < 1) {

                        setMaxPages(Math.floor((data.length / rowsPerPage)) + 1);

                    }
                    else {

                        setMaxPages(Math.floor((data.length / rowsPerPage)));
                    }
                    setEvents(data.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
                    setLoading(false);
                }
            })
        }
        else {
            setMaxPages(trueMaxPage);
            webHelpers.GetAsync('/api/events/?page=' + (page + 1) + '&pageSize=' + rowsPerPage, 'helios-api').then((data: any) => {
                if (data.length === 0 || data.response === 400) {
                    console.log('Unable to fetch permissions with this name, please try another', { 'variant': 'error' });
                }
                else {

                    console.log(data.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
                    setEvents(data)
                    setLoading(false);
                }
            }).catch((error: IWebHelperErrorResponse) => {
                console.log('Unable to fetch permissions with this name, please try another', { 'variant': 'error' });
            });
        }

    }
    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value);
    }
    const handleDescriptionChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDescription(e.currentTarget.value);
    }
    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
        searchEvents(e.currentTarget.value);
    }
    const handleChangeRowsPerPage = (event: any) => {
        setLoading(true);
        setRowsPerPage(event.target[0]);
        setPage(0);
    };
    const handleEventClick = (id: string) => {
        setSelectedEvent(id);
    }
    useEffect(() => {
        searchEvents(search === "" ? "" : search);
    }, [page, rowsPerPage])

    useEffect(() => {
        webHelpers.GetAsync('/api/events/', 'helios-api').then((data: any) => {
            if (data.length === 0 || data.response === 400) {
                console.log('Unable to fetch permissions with this name, please try another', { 'variant': 'error' });
            }
            else {
                setMaxPages(Math.floor((data.length / rowsPerPage)) + 1);
                setTrueMaxPage((Math.floor((data.length / rowsPerPage)) + 1));
                setLoading(false);
            }
        }).catch((error: IWebHelperErrorResponse) => {
            console.log('Unable to fetch permissions with this name, please try another', { 'variant': 'error' });
        });
    }, [])



    useEffect(() => {
        if (selectedEvent === "") {
            setGoToStage2(false);
        }
        else {
            setGoToStage2(true);
        }
    });
    const handleSubmit = () => {
        if (code === '' || selectedEvent === '') {
            toaster.create({

                title: `Please select an event to subscribe to and enter some code before writing to the database`,
                type: "warning",

            })
        }
        else {
            let payload = {
                code: code
            }
            webHelpers.PutAsync('/api/workflow/step/' + props.step.id + '/event?event=' + selectedEvent, 'helios-api', payload).then((data: any) => {
                if (data.response === 400 || data === undefined) {
                    console.log('Some server error creating this event subscriber', { 'variant': 'error' });
                }
                else {
                    handleClose();
                    props.cb();
                    toaster.create({

                        title: `Event subscriber successfully created!`,
                        type: "success",

                    });
                }
            }).catch((error) => {
                handleClose()
                return (
                    toaster.create({

                        title: `That Event already exists and can't be added!`,
                        type: "error",

                    })
                )
            })
        }
    }
    const rowsCollection = createListCollection({
        items: [
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "15", value: 15 }
        ]
    })


    return (
        <>
            <Toaster />
            <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                onClick={handleClickOpen}
            >
                <BiAddToQueue /> Add Event
            </Button>
            <DialogRoot
                open={open}
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={"xl"}
            >
                <Dialog.Backdrop />
                <DialogContent>
                    <DialogTitle>{"Creating Event for: " + props.step.name}<br />
                        <Text as={"i"} marginBottom={"1px"} fontSize={"sm"}>Please select which event you would like to add to the Workflow</Text></DialogTitle>
                    <DialogBody padding={"10px"}>
                        <>
                            <Flex direction={"row"}>
                                <SearchBox search={search} handleSearchChange={handleSearchChange} />
                                <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Rows per Page:</Text>
                                <Flex borderColor={"textPrimary"} borderWidth={"2px"} borderRadius={"sm"} height={"35px"} marginTop={"5px"}>
                                    <SelectRoot collection={rowsCollection} onValueChange={(e) => handleChangeRowsPerPage(e)} value={[String(rowsPerPage)]} width={"30px"} marginRight={"20px"} marginLeft={"5px"} marginTop={"-5px"} size={"md"}>
                                        <SelectTrigger w={"50px"}>
                                            <SelectValueText placeholder={String(rowsPerPage)}></SelectValueText>
                                        </SelectTrigger>
                                        <SelectContent display={"absolute"} portalled={false}>
                                            {rowsCollection.items.map((each) =>
                                                <SelectItem item={each} key={each.value}>{each.label}</SelectItem>
                                            )}
                                        </SelectContent>
                                    </SelectRoot>
                                </Flex>
                                <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Page {page + 1} of {maxPages} </Text>
                                <Button bgColor={"AbleBlue"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                    page !== 0 && setPage(page - 1);
                                    setLoading(true);
                                }} disabled={page === 0}><AiFillCaretLeft /></Button>
                                <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                    page !== maxPages - 1 && setPage(page + 1)
                                    setLoading(true);
                                }} disabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                            </Flex>
                            {events.length === 0 ?
                                <Flex justifyContent={"center"}>
                                    <Text marginTop={"50px"} fontSize={"xl"}>No Workflows Found!</Text>
                                </Flex>
                                :
                                <>
                                    {!loading ?
                                        <Table.Root variant={"line"}>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.ColumnHeader>Name</Table.ColumnHeader>
                                                    <Table.ColumnHeader>ID</Table.ColumnHeader>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {events.map((each) =>
                                                    <Table.Row _hover={{ bg: colorMode === 'light' ? "whitesmoke" : "backgroundLightGrey" }} onClick={() => handleEventClick(each.id)} borderColor={colorMode === 'light' ? "AbleBlue" : "AbleYellow"} borderWidth={each.id === selectedEvent ? "3px" : "0px"}
                                                        bg={each.id === selectedEvent ? (colorMode === 'light' ? "whitesmoke" : "backgroundLightGrey") : "backgroundDarkGrey"} pointerEvents={"visibleStroke"}
                                                        height={"35px"} cursor={"pointer"}>
                                                        <Table.Cell>{(each.name)}</Table.Cell>
                                                        <Table.Cell>{each.id}</Table.Cell>
                                                    </Table.Row>
                                                )}

                                            </Table.Body>

                                        </Table.Root>
                                        :
                                        <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"11%"} bgColor={"backgroundDarkGrey"}>
                                            <Spinner color={colorMode === "light" ? "AbleBlue" : "AbleYellow"} />
                                        </Flex>

                                    }
                                </>
                            }
                        </>
                        <Collapsible.Root open={goToStage2}>
                            {/* need to revisit animations */}
                            <Collapsible.Content>
                                <Text display={code === "" ? "flex" : "none"}>You still need to write some code before you can add this event!</Text>
                                <CodeEditor stepFriendlyName={props.step.name} code_id="" buttonText="Edit Code" mode="create-event" cb={setCode} intital_value={code} quick_outcome_code_id={null} />
                            </Collapsible.Content>
                        </Collapsible.Root>
                    </DialogBody>
                    <DialogFooter>
                        <Flex justifyContent={"space-between"}>
                            <Button bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                                onClick={handleSubmit} disabled={selectedEvent === ""}>Add Event</Button>
                        </Flex>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
