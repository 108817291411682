import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { SignIn } from '../pages/login';
import { Dashboard } from '../pages/dashboard';
// import { JobsheetPage } from '../pages/jobsheet';
import { RequireAuth } from '../context/RequireAuth';
import { AuthProvider } from '../context/AuthContext';
import { CacheProvider } from '../context/CacheContext';
import { StaffPage } from '../pages/staff';
import { PageContainer } from '../components/container';
import { UsersPage } from '../pages/staff/users';
import { EnvironmentProvider } from '../context/EnvironmentContext';
import { WorkflowsPage } from '../pages/workflows';
import { ViewWorkflow } from '../pages/workflows/view';
import { GroupsPage } from '../pages/staff/groups';
import { PermissionsPage } from '../pages/staff/permissions';
import { BlockedNumbersPage } from '../pages/blockedNumbers';
import { TimeprofilesPage } from '../pages/timeProfiles';
import { OutcomesPage } from '../pages/outcomes';
import { InteractionsPage } from '../pages/interactions';
import { LateInvoiceDatePage } from '../pages/lateInvoiceDate';
import { PaymentTermsPage } from '../pages/paymentTerms';

export function PageRoutes() {
    return (
        <CacheProvider>
            <EnvironmentProvider>
                <AuthProvider>
                    <Routes>
                        <Route path="/" element={
                            <Navigate to="/signin" />
                        } />

                        <Route path="/signin" element={<SignIn />} />

                        <Route path="/dashboard" element={
                            <RequireAuth>
                                <PageContainer pageTitle='Dashboard'>
                                    <Dashboard />
                                </PageContainer>
                            </RequireAuth>}
                        />

                        <Route path="/staff" element={
                            <RequireAuth>
                                <PageContainer pageTitle='Staff Management'>
                                    <StaffPage />
                                </PageContainer>
                            </RequireAuth>}
                        />

                        <Route path="/staff/users" element={
                            <RequireAuth>
                                <PageContainer pageTitle='User Management'>
                                    <UsersPage />
                                </PageContainer>
                            </RequireAuth>}
                        />

                        <Route path="/workflows" element={
                            <RequireAuth>
                                <PageContainer pageTitle='Workflow Management'>
                                    <WorkflowsPage />
                                </PageContainer>
                            </RequireAuth>
                        }
                        />
                        <Route path="/workflows/view/:environment/:workflow_id" element={
                            <RequireAuth>
                                <PageContainer pageTitle='Workflow Management'>
                                    <ViewWorkflow />
                                </PageContainer>
                            </RequireAuth>
                        }
                        />
                        <Route path="/staff/groups" element={
                            <RequireAuth>
                                <PageContainer pageTitle='Group Management'>
                                    <GroupsPage/>
                                </PageContainer>
                            </RequireAuth>
                        }
                        />
                        <Route path="/staff/permissions" element={
                            <RequireAuth>
                                <PageContainer pageTitle='Permission Management'>
                                    <PermissionsPage/>
                                </PageContainer>
                            </RequireAuth>
                        }
                        />
                        <Route path="/blocklist" element={
                            <RequireAuth>
                                <PageContainer pageTitle='Blocklist'>
                                    <BlockedNumbersPage/>
                                </PageContainer>
                            </RequireAuth>
                        }
                        />
                        <Route path="/timeprofiles" element={
                            <RequireAuth>
                                <PageContainer pageTitle='Timeprofile Management'>
                                    <TimeprofilesPage/>
                                </PageContainer>
                            </RequireAuth>
                        }
                        />
                        <Route path="/outcomes" element={
                            <RequireAuth>
                                <PageContainer pageTitle='Outcome Management'>
                                    <OutcomesPage/>
                                </PageContainer>
                            </RequireAuth>
                        }
                        />
                        <Route path="/interactions" element={
                            <RequireAuth>
                                <PageContainer pageTitle='Interaction Management'>
                                    <InteractionsPage/>
                                </PageContainer>
                            </RequireAuth>
                        }
                        />
                        <Route path="/lateinvoicedate" element={
                            <RequireAuth>
                                <PageContainer pageTitle='Late Invoice Dates'>
                                    <LateInvoiceDatePage/>
                                </PageContainer>
                            </RequireAuth>
                        }
                        />
                        <Route path="/paymentterms" element={
                            <RequireAuth>
                                <PageContainer pageTitle='Payment Terms'>
                                    <PaymentTermsPage/>
                                </PageContainer>
                            </RequireAuth>
                        }
                        />

                        {/* <Route path="/jobsheet/:jobNumber" element={
                        <RequireAuth>
                            <JobsheetPage/>
                        </RequireAuth> }
                    />

                    <Route path="/*" element={<NotFoundPage />} /> */}

                    </Routes>
                </AuthProvider>
            </EnvironmentProvider>
        </CacheProvider>
    )
}