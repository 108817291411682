import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Table, Select, Text, Tabs,
    useDisclosure,
    Flex,
    Accordion,
    TabsIndicator,
    createListCollection,
    Spinner
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { IoMan } from "react-icons/io5"
import { useDictionary } from "../../scripts/useDictionary";
import GetUserConfirm from "../confirm";
import AddSkill from "./addSkill";
import { StringMappingType } from "typescript";
import AddSkillRow from "./addSkillRow";
import { AiFillCaretLeft } from "react-icons/ai"
import { AiFillCaretRight } from "react-icons/ai";
import { floor } from 'mathjs'
import { useEnvironment } from "../../context/EnvironmentContext";
import { FaHandPointLeft } from "react-icons/fa";
import { useColorMode } from "../ui/color-mode";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { DialogActionTrigger, DialogBody, DialogCloseTrigger, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../../components/ui/dialog";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from "../ui/select";


interface SkillsDialogProps {
    userId: string,
    role: UserRole,
    submitAddedSkills: Function,
    removeSkill: Function,
    refreshRoles: Function,
    isSelected: boolean,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}
export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
export interface SkillTest {
    skill_id: string,
    level_id: string,
    level_name: string,
    name: string,
}
export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: SkillTest[],
}

const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: SkillTest }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}
//const skills: { [skill_id: string]: SkillTest } = {};

export default function SkillsView(props: SkillsDialogProps) {
    const { open, onOpen, onClose, onToggle } = useDisclosure();
    const { colorMode, toggleColorMode } = useColorMode();
    const CacheContext = useCache();
    const [role, setRole] = useState(props.role);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [skillToDelete, setSkillToDelete] = useState<string>();
    const [userSkills, setUserSkills] = useState<Detail[]>(props.role.details.sort());
    const [pageUserSkills, setPageUserSkills] = useState<Detail[]>(props.role.details.sort());
    const [allSkills, setAllSkills] = useState<Skill[]>([]);
    const [pageSkills, setPageSkills] = useState<Skill[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);



    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();
    const webHelpers = useWebHelper();
    const EnvironmentContext = useEnvironment();


    let levels: Level[] = [];
    props.role.details.forEach(detail => {
        levels.push(detail.level)
    })
    function addSkills(additionalSkills: SkillTest[]) {
        let test_object = {
            skill_id: "",
            level_id: "",
            name: "",
            level_name: "",
        }
        // @ts-ignore
        test_object.skill_id = Object.values(additionalSkills)[0];
        // @ts-ignore
        test_object.level_id = Object.values(additionalSkills)[1];
        // @ts-ignore
        test_object.name = Object.values(additionalSkills)[2];
        // @ts-ignore
        test_object.level_name = Object.values(additionalSkills)[3];
        skills[test_object.skill_id] = test_object;
        updateRole();

        return (
            toaster.create({

                title: `permission ${test_object.name} has been added to user ${props.userId}`,
                type: "success",

            })
        )
    }

    const handleDelete = (id: string) => {
        //setConfirmOpen(true);
        setSkillToDelete(id);
        handleConfirm(id);
    }

    function updateRole() {
        let updatedRole: UserRoleUpdate = {
            ...props.role,
            skills: Object.values(skills),
        }
        // Push up the new skill level
        webHelpers.PostAsync('/api/worker/role', 'zeus-api', updatedRole).then((data: any) => {
            if (data != undefined) {
                setRole(data);
                fetchSkills();
                toaster.create({

                    title: `Role updated!`,
                    type: "success",

                });
            }
            else {
                toaster.create({

                    title: `${data.status}: Unable to apply changes to the role`,
                    type: "error",

                });
                //enqueueSnackbar(`${data.status}: Unable to apply changes to the role`, { 'variant': 'error' })
            }
        });

        // Update the client side role
        //props.refreshRoles();

    }

    const handleConfirm = (id: string) => {
        if (id !== '') {
            //props.removeSkill(skillToDelete);
            removeSkill(id);
            setConfirmOpen(false);
            fetchSkills();

        }
    }

    function removeSkill(remove_skill_id: string) {
        for (let remove_skill in skills) {
            if (skills.hasOwnProperty(remove_skill_id)) {
                delete skills[remove_skill_id];
                console.log("updating role: ", skills);
                updateRole();
                populateSkills();
            }
        }

    }
    // Below effect is used purely for debugging
    // useEffect(() => {
    //     console.log("page user skills = ", pageUserSkills);
    //     console.log("unpaged user skills = ", userSkills);
    // },[pageUserSkills, userSkills])

    function fetchSkills() {
        // For updating the skills chips on change
        webHelpers.GetAsync('/api/worker/roles/' + props.userId, 'zeus-api').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                toaster.create({

                    title: `Unable to fetch skills!`,
                    type: "error",

                });
            }
            else {
                data.forEach((role: UserRole) => {

                    if (role.id == props.role.id) {
                        Object.values(skills).forEach(skill => {
                            delete skills[skill.skill_id];
                        })
                        setUserSkills(role.details.sort());
                        role.details.forEach((detail: Detail) => {
                            skills[detail.skill_id] = {
                                skill_id: detail.skill_id,
                                level_id: detail.level_id,
                                level_name: detail.level.name,
                                name: detail.id,
                            };
                        });
                        if (Object.keys(skills).length / rowsPerPage === 0) {
                            setMaxPagesFirstTab(Math.floor((Object.keys(skills).length / rowsPerPage) - 1));

                        }
                        else {
                            setMaxPagesFirstTab(Math.floor((Object.keys(skills).length / rowsPerPage)));
                        }
                        setPageUserSkills((role.details.sort()).slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
                    }
                })


            }
        });
    }

    useEffect(() => {
        role.details.forEach((detail: Detail) => {
            skills[detail.skill_id] = {
                skill_id: detail.skill_id,
                level_id: detail.level_id,

                level_name: detail.level.name,
                name: detail.id,
            };
        });
    }, [skills])

    useEffect(() => {
        if (open && allSkills === undefined) {
            fetchSkills();
            webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
                setAllSkills(data)
            });
        }
    }, [open]);

    const handleChangeRowsPerPage = (event: any) => {
        setLoading(true);
        setRowsPerPage(event.value[0]);
        setPage(0);
    };

    const populateSkills = () => {
        setLoading(true);
        webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
            setAllSkills(data)
            if (data.length % rowsPerPage === 0) {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)));
            }
            else {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)) + 1);
            }
            setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
            setLoading(false);
        });
    }
    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        setPageUserSkills(userSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))

    }, [page])

    useEffect(() => {
        //this will reset the table whenever the rowsPerPage is changed
        setPage(0);
        populateSkills();
        fetchSkills();
    }, [rowsPerPage])

    const rowsCollection = createListCollection({
        items: [
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "15", value: 15 }
        ]
    })


    // useEffect(() => {
    //     onClearSkills();
    // }, [open])

    return (
        <>
            <Toaster />
            <DialogRoot
                aria-describedby="alert-dialog-slide-description"
                size={{ base: "lg", lg: "lg" }}

            >
                <DialogTrigger>
                    <Button bg={props.isSelected ? "AbleBlue" : "backgroundDarkGrey"} color={colorMode === 'light' ? props.isSelected ? "white" : "AbleBlue" : props.isSelected ? "white" : "white"}
                        _hover={props.isSelected ? { bg: "AbleBlue", color: "AbleYellow" } : { bg: "backgroundDarkGrey", color: "AbleYellow" }} overrideBgColor={true}
                        onClick={() => {
                            onOpen();
                            setPage(0);
                            populateSkills();
                        }}>
                        <Text color={colorMode === "light" ? (props.isSelected ? "white" : "AbleBlue") : props.isSelected ? "white" : "white"} display={"flex"} flexDirection={"row"}>
                            <IoMan />Skills
                        </Text>
                    </Button>
                </DialogTrigger>
                <Dialog.Backdrop />
                <DialogContent>
                    <DialogTitle>{"Edit " + props.role.name + " Skills"}
                    </DialogTitle
                    >
                    <DialogBody padding={"10px"}>
                        {userSkills.length === 0 || allSkills === undefined ? (
                            <>
                                <p>User doesn't have any skills!</p>
                                <AddSkill role={props.role} handleAddSkills={addSkills} updateSkills={fetchSkills} />
                            </>

                        ) : (
                            <Tabs.Root position="relative" size={"lg"} defaultValue={"Active Skills"} variant={"subtle"} onChange={() => {
                            }
                            }>
                                <Tabs.List marginTop={"10px"} >
                                    <Tabs.Trigger onClick={() => { setPage(0); populateSkills() }} borderRightRadius={0} padding={"10px"} borderTopLeftRadius={"md"} value="Active Skills" bg={"AbleBlue"} color={"white"} _selected={{ color: "AbleYellow" }} as={"b"}>Active Skills</Tabs.Trigger>
                                    <Tabs.Trigger onClick={() => { setPage(0); populateSkills(); }} borderLeftRadius={0} padding={"10px"} borderTopRightRadius={"md"} value="Add Skills" bg={"AbleBlue"} color={"white"} _selected={{ color: "AbleYellow" }} as={"b"}>Add Skills</Tabs.Trigger>
                                </Tabs.List>
                                <Tabs.Content value={"Active Skills"}>
                                    <Flex direction={"row"} justifyContent={"right"}>
                                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Rows per Page:</Text>
                                        <Flex borderColor={"textPrimary"} borderWidth={"2px"} borderRadius={"sm"} height={"35px"} marginTop={"5px"}>
                                            <SelectRoot collection={rowsCollection} onValueChange={(e) => handleChangeRowsPerPage(e)} value={[String(rowsPerPage)]} width={"30px"} marginRight={"20px"} marginLeft={"5px"} marginTop={"-5px"} size={"md"}>
                                                <SelectTrigger w={"50px"}>
                                                    <SelectValueText placeholder={String(rowsPerPage)}></SelectValueText>
                                                </SelectTrigger>
                                                <SelectContent display={"absolute"} portalled={false}>
                                                    {rowsCollection.items.map((each) =>
                                                        <SelectItem item={each} key={each.value}>{each.label}</SelectItem>
                                                    )}
                                                </SelectContent>
                                            </SelectRoot>
                                        </Flex>
                                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Page {page + 1} of {maxPageFirstTab + 1} </Text>
                                        <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                            page !== 0 && setPage(page - 1);
                                        }} disabled={page === 0}><AiFillCaretLeft /></Button>
                                        <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                            page !== maxPageFirstTab + 1 && setPage(page + 1)
                                        }} disabled={page === maxPageFirstTab}><AiFillCaretRight /></Button>
                                    </Flex>
                                    <Table.Root variant={"line"}>
                                        <Table.Caption>Current Environment : {(CacheContext.lookUpCacheItem("environment") !== null && CacheContext.lookUpCacheItem("environment") !== undefined) ?
                                            CacheContext.lookUpCacheItem("environment") : "live"}</Table.Caption>
                                        <Table.Header>
                                            <Table.Row bg={"backgroundDarkGrey"}>
                                                <Table.ColumnHeader display={{ base: "none", lg: "revert" }}>Skill Name</Table.ColumnHeader>
                                                <Table.ColumnHeader display={{ base: "none", lg: "revert" }}>Skill Level</Table.ColumnHeader>
                                                <Table.ColumnHeader></Table.ColumnHeader>
                                            </Table.Row>
                                        </Table.Header>
                                        {loading ?
                                            <Flex direction={"row"} justifyContent={"center"} pos={"absolute"} top={"60%"} width={"100%"} marginTop={"11%"} bgColor={"backgroundGrey"}>
                                                <Spinner color={colorMode === "light" ? "AbleBlue" : "AbleYellow"} />
                                            </Flex> :
                                            <Table.Body>
                                                <Accordion.Root multiple={false} display={{ base: "revert", lg: "none" }} marginLeft={"-30px"}>
                                                    {pageUserSkills.map(skill => (
                                                        <Accordion.Item value={skill.id}>
                                                            <Accordion.ItemTrigger>
                                                                <Flex justifyContent={"space-between"} w={"100%"}>
                                                                    <Table.Cell display={{ base: "revert", lg: "none" }}>
                                                                        {skill.skill.name}
                                                                        <br />
                                                                        <Text as={"i"} fontSize={"2xs"}>{"(" + skill.level.name + ")"}</Text> </Table.Cell>
                                                                    {/* <AccordionIcon /> */}
                                                                </Flex>
                                                            </Accordion.ItemTrigger>
                                                            <Accordion.ItemContent w={"1000px"}>
                                                                <Table.Cell display={{ base: "revert", lg: "none" }} ><GetUserConfirm confirmFunction={() => handleDelete(skill.skill_id)} declineFunction={() => { console.log("decline") }} buttonText={"Delete"} buttonWarning="Are you sure you wamt to delete this skill?" />
                                                                </Table.Cell>
                                                            </Accordion.ItemContent>
                                                        </Accordion.Item>
                                                    ))}
                                                </Accordion.Root>
                                                {pageUserSkills.map(skill => (
                                                    <Table.Row bg={"backgroundGrey"}>
                                                        <Table.Cell display={{ base: "none", lg: "revert" }}>{skill.skill.name}</Table.Cell>
                                                        <Table.Cell display={{ base: "none", lg: "revert" }} >{"(" + skill.level.name + ")"}</Table.Cell>

                                                        <Table.Cell display={{ base: "none", lg: "revert" }} ><GetUserConfirm confirmFunction={() => handleDelete(skill.skill_id)} declineFunction={() => { console.log("decline") }} buttonText={"Delete"} buttonWarning="Are you sure you wamt to delete this skill?" />
                                                        </Table.Cell>
                                                    </Table.Row>
                                                ))}
                                                {/* <AddSkill role={props.role} handleAddSkills={addSkills} updateSkills={fetchSkills} /> */}
                                            </Table.Body>
                                        }
                                    </Table.Root>
                                </Tabs.Content>
                                <Tabs.Content value={"Add Skills"}>
                                    <Flex direction={"row"} justifyContent={"right"}>
                                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Rows per Page:</Text>
                                        <Flex borderColor={"textPrimary"} borderWidth={"2px"} borderRadius={"sm"} height={"35px"} marginTop={"5px"}>
                                            <SelectRoot collection={rowsCollection} onValueChange={(e) => handleChangeRowsPerPage(e)} value={[String(rowsPerPage)]} width={"30px"} marginRight={"20px"} marginLeft={"5px"} marginTop={"-5px"} size={"md"}>
                                                <SelectTrigger w={"50px"}>
                                                    <SelectValueText placeholder={String(rowsPerPage)}></SelectValueText>
                                                </SelectTrigger>
                                                <SelectContent display={"absolute"} portalled={false}>
                                                    {rowsCollection.items.map((each) =>
                                                        <SelectItem item={each} key={each.value}>{each.label}</SelectItem>
                                                    )}
                                                </SelectContent>
                                            </SelectRoot>
                                        </Flex>
                                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Page {page + 1} of {maxPages} </Text>
                                        <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                            page !== 0 && setPage(page - 1);
                                        }} disabled={page === 0}><AiFillCaretLeft /></Button>
                                        <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                            page !== maxPages - 1 && setPage(page + 1)
                                        }} disabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                                    </Flex>
                                    {/* need to have a section or method of showing already selected skills */}
                                    <Table.Root variant={"line"}>
                                        <Table.Header>
                                            <Table.Row bg={"backgroundDarkGrey"}>
                                                {/* this will show all skill that are possible to see */}
                                                <Table.ColumnHeader>Skill Name</Table.ColumnHeader>
                                                <Table.ColumnHeader display={{ base: "none", lg: "flex" }}>Skill Level</Table.ColumnHeader>
                                                <Table.ColumnHeader></Table.ColumnHeader>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {loading ?
                                                <Flex direction={"row"} justifyContent={"center"} pos={"absolute"} top={"60%"} width={"100%"} marginTop={"11%"} bgColor={"backgroundGrey"}>
                                                    <Spinner color={colorMode === "light" ? "AbleBlue" : "AbleYellow"} />
                                                </Flex> :
                                                <>
                                                    {pageSkills.map((skill) =>

                                                        <AddSkillRow {...skill} handleAddedSkills={addSkills} />
                                                    )}
                                                </>
                                            }
                                        </Table.Body>
                                    </Table.Root>
                                </Tabs.Content>
                            </Tabs.Root>
                        )}
                    </DialogBody>
                    <DialogFooter>
                        <DialogActionTrigger>
                            <Button>Finish</Button>
                        </DialogActionTrigger>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot >
        </>
    );
}
