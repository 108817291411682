import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Fieldset, Input, useDisclosure
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import { Toaster, toaster } from "../../components/ui/toaster";
import { Button } from "../../components/ui/button";
import { DialogBody, DialogContent, DialogFooter, DialogRoot, DialogTitle, DialogTrigger } from "../../components/ui/dialog";

interface CreatePaymentTermProps {
    cb: Function,
}
export default function CreatePaymentTerm(props: CreatePaymentTermProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [name, setName] = useState<string>("");
    const [intacctId, setIntacctId] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [forcePassword, setForcePassword] = useState<boolean>(false);
    const [isSecurity, setIsSecurity] = useState<boolean>(false);

    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
        setName("");
        setIntacctId("");
    }

    const handleClose = () => {
        onClose();
    }

    const handleForcePasswordChange = () => setForcePassword(!forcePassword);

    const createPermission = () => {
        webHelpers.PutAsync('/api/sales/customers/paymentterm/create', 'sales', { name: name, intacct_id: intacctId }).then((data: any) => {
            setLoading(false);
            if (data.name !== name || data.intacct_id !== intacctId) {

                toaster.create({

                    title: `${data.status}: Unable to create Payment Term with Name: ${name}.`,
                    type: "error",

                });
            }
            else {
                handleClose();
                props.cb();
                onClose();
                toaster.create({

                    title: `Payment Term ${data.name} successfully created!`,
                    type: "success",

                });
            }

        }).catch((error) => {
            setLoading(false);
            toaster.create({

                title: `${error.status}: Unable to create Payment Term with Name: ${name}.`,
                type: "error",

            });
        })
    }
    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }
    const handleIntacctIdChange = (e: React.FormEvent<HTMLInputElement>) => {
        setIntacctId(e.currentTarget.value)
    }

    return (
        <>
            <Toaster />
            <DialogRoot
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Dialog.Backdrop />
                <DialogTrigger >
                    <Button bg={"AbleBlue"} color={"white"} size={"sm"} marginLeft={"10px"}
                        _hover={{ bg: "AbleYellow", color: "white" }}
                        _active={{ bg: "#ECB500" }}
                        onClick={handleClickOpen}>

                        <BiPlus /> Create Payment Term
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogTitle>{"Create New Payment Term"}</DialogTitle>
                    <DialogBody padding={"10px"}>

                        <Fieldset.Root>
                            <Fieldset.HelperText>Please complete the form, ensuring all fields are correct, before submitting.</Fieldset.HelperText>

                            <Input placeholder="Name" type="text" autoComplete="new-password" value={name} onChange={handleNameChange}></Input>
                            <Input placeholder="Intacct ID" type="text" autoComplete="new-password" value={intacctId} onChange={handleIntacctIdChange}></Input>

                        </Fieldset.Root>


                    </DialogBody>
                    <DialogFooter>
                        <Button onClick={() => {
                            setLoading(true);
                            createPermission();

                        }}
                            loading={loading}
                            bg={"AbleBlue"}
                            color={"white"}
                            _hover={{ color: "AbleYellow" }}>Create Permission</Button>
                        <DialogTrigger>
                            <Button onClick={onClose} colorScheme="red" marginLeft={"10px"}>Cancel</Button>
                        </DialogTrigger>

                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
